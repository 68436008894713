.green_segment_active {
  @apply shadow-[0px_4px_4px_0px_#4DA454_inset];
}

.purple_segment_active {
  @apply shadow-[0px_4px_4px_0px_#A27BAB_inset];
}

.green_segment:hover {
  border-color: #4da454;
}

.purple_segment:hover {
  border-color: #d9a8e5;
}

.green_segment,
.purple_segment {
  @apply flex items-center cursor-pointer px-[18px] flex-wrap gap-[20px] min-h-[65px] md:max-w-[250px] text-[16px] leading-[20px] font-[400] bg-white border-1 rounded-[5px];
}
