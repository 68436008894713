.checkmark:after {
  position: absolute;
  transform: rotate(45deg);
  content: '';
  display: none;
  left: 7px;
  top: 1px;
  width: 8px;
  height: 14px;
  border: solid #09af77;
  border-width: 0 2px 2px 0;
}
