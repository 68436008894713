.radio-item input[type='radio'] {
  display: none;
}

.radio-item label {
  color: #262628;
  font-size: 16px;
  cursor: pointer;
}

.radio-item label:before {
  content: ' ';
  margin: 0 5px 0 0;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid #09af77;
  background-color: transparent;
  vertical-align: middle;
}

.radio-item input[type='radio']:checked + label:before {
  content: ' ';
  border: 7px solid #09af77;
}
