.button {
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  color: #ffffff;

  padding: 16px 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition-duration: 300ms;
  white-space: nowrap;
}
.horizontal:hover {
  padding: 16px 18px;
}

.both:hover {
  transform: scale(0.9);
}

.light.both:hover {
  background-color: #ffffff;
  box-shadow: 0px 4px 4px 0px #01432d61 inset;
  color: #262628;
}

.horizontal:active {
  padding: 16px 18px;
}
.both:active {
  transform: scale(0.9);
}

.primary {
  box-shadow: 0px 7px 10.3px 0px rgba(9, 175, 119, 0.48);
  border: 1px solid #09af77;
  border-radius: 10px;
  background-color: #09af77;
}
.primary:hover {
  box-shadow: unset;
}

.primary:active {
  background-color: #ffffff;
  color: #262628;
}

.primaryKsa {
  box-shadow: 0px 7px 10.3px 0px #00000066;
  border: 1px solid #09af77;
  border-radius: 10px;
  background-color: #09af77;
  transition:
    width 0.3s ease,
    box-shadow 0.3s ease;
}
.primaryKsa:hover {
  box-shadow: unset;
}

.primaryKsa:active {
  box-shadow: 0px 4px 4px 0px #01553961 inset;
  background-color: #ffffff;
  color: #262628;
}

.redButton {
  box-shadow: 0px 7px 10.3px 0px #00000066;
  border: 1px solid #b02a09;
  border-radius: 10px;
  background-color: #b02a09;
  transition:
    width 0.3s ease,
    box-shadow 0.3s ease;
}

.redButton:hover {
  box-shadow: unset;
}

.redButton:active {
  background-color: #f5eae7;
  border: 1px solid #b02a09;
  box-shadow: 0px 4px 4px 0px #4a0b0b4d inset;
  color: #262628;
}

.redLineButton {
  box-shadow: 0px 4px 7.5px 0px #40100452;
  border: 1px solid #b02a09;
  border-radius: 10px;
  background-color: transparent;
}

.redLineButton:hover {
  box-shadow: unset;
}

.redLineButton:active {
  box-shadow: 0px 4px 4px 0px #4a0b0b4a inset;
}

.blueButton {
  box-shadow: 0px 7px 10.3px 0px #00000066;
  border: 1px solid #0052b4;
  border-radius: 10px;
  background-color: #0052b4;
}

.blueButton:hover {
  box-shadow: unset;
}

.blueButton:active {
  background-color: #fafffd;
  border: 1px solid #0052b4;
  box-shadow: 0px 4px 4px 0px #0052b44a inset;
  color: #262628;
}

.black {
  box-shadow: 0px 4px 7.5px 0px rgba(38, 38, 40, 0.5);
  border: 1px solid #262628;
  border-radius: 10px;
  background-color: #262628;
}
.black:hover {
  background-color: #ffffff;
  color: #262628;
}
.black:active {
  background-color: #ffffff;
}

.secondary {
  box-shadow: 0px 7px 10.3px 0px rgba(38, 38, 40, 0.5);
  border: 1px solid #eae4ec;
  border-radius: 10px;
  background-color: #eae4ec;
  color: #262628;
}

.secondary:hover {
  background-color: #ffffff;
  border: 1px solid #d9a8e5;
  box-shadow: none;
}

.secondary:active {
  background-color: #09af77;
  border: 1px solid #eae4ec;
  box-shadow: 0px 4px 4px 0px rgba(38, 38, 40, 0.5) inset;
}

.vertical {
  top: 310px;
  right: -38px;
  color: #262628;
  border-radius: 10px 10px 0 0;
  border: 1px solid #09af77;
  transform: scale(1) rotate(-90deg);
}
.vertical:hover {
  transform: scale(0.9) rotate(-90deg);
}

.primaryVideo {
  box-shadow: 0px 4px 7.5px 0px #09af7780;
  border: 1px solid #09af77;
  border-radius: 10px;
  background-color: #09af77;
  transition:
    width 0.3s ease,
    box-shadow 0.3s ease;
}
.primaryVideo:hover {
  box-shadow: unset;
}

.primaryVideo:active {
  box-shadow: 0px 4px 4px 0px #01553961 inset;
  background-color: #ffffff;
  color: #262628;
}

.blackVideo {
  box-shadow: 0px 4px 7.5px 0px #26262880;

  border: 1px solid #262628;
  border-radius: 10px;
  background-color: #262628;
  transition:
    width 0.3s ease,
    box-shadow 0.3s ease;
}
.blackVideo:hover {
  box-shadow: unset;
}
.blackVideo:active {
  color: #262628;
  background-color: #ffffff;
  box-shadow: 0px 4px 4px 0px #26262861 inset;
}

.blueLine {
  box-shadow: 0px 4px 7.5px 0px #0052b480;

  border: 1px solid #0052b4;
  border-radius: 10px;

  color: #0052b4;
  transition:
    width 0.3s ease,
    box-shadow 0.3s ease;
}
.blueLine:hover {
  box-shadow: unset;
}
.blueLine:active {
  background-color: #f6faff;
  box-shadow: 0px 4px 4px 0px #0052b44a inset;
}

.noLine {
  border-width: 1px;

  border-radius: 10px;
  box-sizing: border-box;
  color: #09af77;
}
.noLine:hover {
  border: 1px solid #09af77;
}
.noLine:active {
  border: 1px solid #09af77;
  color: #262628;
}
