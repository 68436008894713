.ArrowButton_circle__lWKOZ {
  height: 36px;
  width: 36px;

  cursor: pointer;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.ArrowButton_circle__lWKOZ:hover {
  background-color: #09af77;
}

.Button_button__AlQaw {
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  color: #ffffff;

  padding: 16px 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition-duration: 300ms;
  white-space: nowrap;
}
.Button_horizontal____QTZ:hover {
  padding: 16px 18px;
}

.Button_both__BXik9:hover {
  transform: scale(0.9);
}

.Button_light__JsKN5.Button_both__BXik9:hover {
  background-color: #ffffff;
  box-shadow: 0px 4px 4px 0px #01432d61 inset;
  color: #262628;
}

.Button_horizontal____QTZ:active {
  padding: 16px 18px;
}
.Button_both__BXik9:active {
  transform: scale(0.9);
}

.Button_primary__fea80 {
  box-shadow: 0px 7px 10.3px 0px rgba(9, 175, 119, 0.48);
  border: 1px solid #09af77;
  border-radius: 10px;
  background-color: #09af77;
}
.Button_primary__fea80:hover {
  box-shadow: unset;
}

.Button_primary__fea80:active {
  background-color: #ffffff;
  color: #262628;
}

.Button_primaryKsa__iOtNG {
  box-shadow: 0px 7px 10.3px 0px #00000066;
  border: 1px solid #09af77;
  border-radius: 10px;
  background-color: #09af77;
  transition:
    width 0.3s ease,
    box-shadow 0.3s ease;
}
.Button_primaryKsa__iOtNG:hover {
  box-shadow: unset;
}

.Button_primaryKsa__iOtNG:active {
  box-shadow: 0px 4px 4px 0px #01553961 inset;
  background-color: #ffffff;
  color: #262628;
}

.Button_redButton__bxim7 {
  box-shadow: 0px 7px 10.3px 0px #00000066;
  border: 1px solid #b02a09;
  border-radius: 10px;
  background-color: #b02a09;
  transition:
    width 0.3s ease,
    box-shadow 0.3s ease;
}

.Button_redButton__bxim7:hover {
  box-shadow: unset;
}

.Button_redButton__bxim7:active {
  background-color: #f5eae7;
  border: 1px solid #b02a09;
  box-shadow: 0px 4px 4px 0px #4a0b0b4d inset;
  color: #262628;
}

.Button_redLineButton__hZ_YH {
  box-shadow: 0px 4px 7.5px 0px #40100452;
  border: 1px solid #b02a09;
  border-radius: 10px;
  background-color: transparent;
}

.Button_redLineButton__hZ_YH:hover {
  box-shadow: unset;
}

.Button_redLineButton__hZ_YH:active {
  box-shadow: 0px 4px 4px 0px #4a0b0b4a inset;
}

.Button_blueButton__wvXK_ {
  box-shadow: 0px 7px 10.3px 0px #00000066;
  border: 1px solid #0052b4;
  border-radius: 10px;
  background-color: #0052b4;
}

.Button_blueButton__wvXK_:hover {
  box-shadow: unset;
}

.Button_blueButton__wvXK_:active {
  background-color: #fafffd;
  border: 1px solid #0052b4;
  box-shadow: 0px 4px 4px 0px #0052b44a inset;
  color: #262628;
}

.Button_black__MR639 {
  box-shadow: 0px 4px 7.5px 0px rgba(38, 38, 40, 0.5);
  border: 1px solid #262628;
  border-radius: 10px;
  background-color: #262628;
}
.Button_black__MR639:hover {
  background-color: #ffffff;
  color: #262628;
}
.Button_black__MR639:active {
  background-color: #ffffff;
}

.Button_secondary__tl__f {
  box-shadow: 0px 7px 10.3px 0px rgba(38, 38, 40, 0.5);
  border: 1px solid #eae4ec;
  border-radius: 10px;
  background-color: #eae4ec;
  color: #262628;
}

.Button_secondary__tl__f:hover {
  background-color: #ffffff;
  border: 1px solid #d9a8e5;
  box-shadow: none;
}

.Button_secondary__tl__f:active {
  background-color: #09af77;
  border: 1px solid #eae4ec;
  box-shadow: 0px 4px 4px 0px rgba(38, 38, 40, 0.5) inset;
}

.Button_vertical__eY3pi {
  top: 310px;
  right: -38px;
  color: #262628;
  border-radius: 10px 10px 0 0;
  border: 1px solid #09af77;
  transform: scale(1) rotate(-90deg);
}
.Button_vertical__eY3pi:hover {
  transform: scale(0.9) rotate(-90deg);
}

.Button_primaryVideo__iDq1p {
  box-shadow: 0px 4px 7.5px 0px #09af7780;
  border: 1px solid #09af77;
  border-radius: 10px;
  background-color: #09af77;
  transition:
    width 0.3s ease,
    box-shadow 0.3s ease;
}
.Button_primaryVideo__iDq1p:hover {
  box-shadow: unset;
}

.Button_primaryVideo__iDq1p:active {
  box-shadow: 0px 4px 4px 0px #01553961 inset;
  background-color: #ffffff;
  color: #262628;
}

.Button_blackVideo__5Kxtb {
  box-shadow: 0px 4px 7.5px 0px #26262880;

  border: 1px solid #262628;
  border-radius: 10px;
  background-color: #262628;
  transition:
    width 0.3s ease,
    box-shadow 0.3s ease;
}
.Button_blackVideo__5Kxtb:hover {
  box-shadow: unset;
}
.Button_blackVideo__5Kxtb:active {
  color: #262628;
  background-color: #ffffff;
  box-shadow: 0px 4px 4px 0px #26262861 inset;
}

.Button_blueLine__ZHzzr {
  box-shadow: 0px 4px 7.5px 0px #0052b480;

  border: 1px solid #0052b4;
  border-radius: 10px;

  color: #0052b4;
  transition:
    width 0.3s ease,
    box-shadow 0.3s ease;
}
.Button_blueLine__ZHzzr:hover {
  box-shadow: unset;
}
.Button_blueLine__ZHzzr:active {
  background-color: #f6faff;
  box-shadow: 0px 4px 4px 0px #0052b44a inset;
}

.Button_noLine__Mv2fc {
  border-width: 1px;

  border-radius: 10px;
  box-sizing: border-box;
  color: #09af77;
}
.Button_noLine__Mv2fc:hover {
  border: 1px solid #09af77;
}
.Button_noLine__Mv2fc:active {
  border: 1px solid #09af77;
  color: #262628;
}

.Checkbox_checkmark__MCE_d:after {
  position: absolute;
  transform: rotate(45deg);
  content: '';
  display: none;
  left: 7px;
  top: 1px;
  width: 8px;
  height: 14px;
  border: solid #09af77;
  border-width: 0 2px 2px 0;
}

.Radio_radio-item__C3npE input[type='radio'] {
  display: none;
}

.Radio_radio-item__C3npE label {
  color: #262628;
  font-size: 16px;
  cursor: pointer;
}

.Radio_radio-item__C3npE label:before {
  content: ' ';
  margin: 0 5px 0 0;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid #09af77;
  background-color: transparent;
  vertical-align: middle;
}

.Radio_radio-item__C3npE input[type='radio']:checked + label:before {
  content: ' ';
  border: 7px solid #09af77;
}

.Segment_green_segment_active__PWkdm {
  --tw-shadow: 0px 4px 4px 0px #4DA454 inset;
  --tw-shadow-colored: inset 0px 4px 4px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.Segment_purple_segment_active__5fPNh {
  --tw-shadow: 0px 4px 4px 0px #A27BAB inset;
  --tw-shadow-colored: inset 0px 4px 4px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.Segment_green_segment__ezvly:hover {
  border-color: #4da454;
}

.Segment_purple_segment__C1fZi:hover {
  border-color: #d9a8e5;
}

.Segment_green_segment__ezvly, .Segment_purple_segment__C1fZi {
  display: flex;
  min-height: 65px;
  cursor: pointer;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
  border-radius: 5px;
  border-width: 1px;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding-left: 18px;
  padding-right: 18px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

@media (min-width: 768px) {

  .Segment_green_segment__ezvly, .Segment_purple_segment__C1fZi {
    max-width: 250px;
  }
}

.SegmentSingle_green_segment_active__TQJwe {
  --tw-shadow: 0px 4px 4px 0px #4DA454 inset;
  --tw-shadow-colored: inset 0px 4px 4px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.SegmentSingle_purple_segment_active__eBmQJ {
  --tw-shadow: 0px 4px 4px 0px #A27BAB inset;
  --tw-shadow-colored: inset 0px 4px 4px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.SegmentSingle_green_segment__zFA59:hover {
  border-color: #4da454;
}

.SegmentSingle_purple_segment__qG5hi:hover {
  border-color: #d9a8e5;
}

.SegmentSingle_green_segment__zFA59, .SegmentSingle_purple_segment__qG5hi {
  display: flex;
  min-height: 68px;
  cursor: pointer;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
  border-radius: 5px;
  border-width: 1px;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding-left: 18px;
  padding-right: 18px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

@media (min-width: 768px) {

  .SegmentSingle_green_segment__zFA59, .SegmentSingle_purple_segment__qG5hi {
    max-width: 250px;
  }
}

.SegmentSingleMob_green_segment_active__2j8A_ {
  --tw-shadow: 0px 4px 4px 0px #4DA454 inset;
  --tw-shadow-colored: inset 0px 4px 4px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.SegmentSingleMob_purple_segment_active__f9pF4 {
  --tw-shadow: 0px 4px 4px 0px #A27BAB inset;
  --tw-shadow-colored: inset 0px 4px 4px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.SegmentSingleMob_green_segment__m3eaq:hover {
  border-color: #4da454;
}

.SegmentSingleMob_purple_segment__eOcEc:hover {
  border-color: #d9a8e5;
}

.SegmentSingleMob_green_segment__m3eaq, .SegmentSingleMob_purple_segment__eOcEc {
  display: flex;
  min-height: 68px;
  cursor: pointer;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
  border-radius: 5px;
  border-width: 1px;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding-left: 18px;
  padding-right: 18px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

@media (min-width: 768px) {

  .SegmentSingleMob_green_segment__m3eaq, .SegmentSingleMob_purple_segment__eOcEc {
    max-width: 250px;
  }
}

