.circle {
  height: 36px;
  width: 36px;

  cursor: pointer;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.circle:hover {
  background-color: #09af77;
}
